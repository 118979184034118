<template>
    <app-wrapper>
        <template v-slot:child>
            <!--Balance sheet -->
            <div class="w-90 center">
                <router-link :to="{ name: 'Reports' }" class="pv4-l pv3 mini-spacing"> &lt; Reports </router-link>

                <div class="flex justify-between items-center pb3 box-border-bottom">
                    <div>
                        <p class="f3 b">Inventory Report</p>
                    </div>
                    <div>
                        <button>Update</button>
                    </div>
                </div>
            </div>
            <!--Inventory Report sheet -->

            <!-- date, comparison & filter  -->
            <div class="w-90 center pt4">
                <div class="flex justify-between">
                    <div></div>

                    <div class="flex items-center flex-wrap justify-end items-center w-100 pt0-l pt3" style="gap: 1rem">
                        <div class="flex flex-wrap" style="gap: 1rem">
                            <input type="date" name="startDate" class="input-style" v-model="state.startDate" />
                            <input type="date" name="endDate" class="input-style" v-model="state.endDate" />

                            <!-- <template>
                                <input type="date" name="" class="input-style" />
                                <input type="date" name="" class="input-style" />
                            </template> -->
                        </div>

                        <select name="comparison" id="filter" v-model="state.filter">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Annually">Annually</option>
                        </select>

                        <button class="filter card-boxx" 
                            title="Refresh"
                            @click="handleInventoryRecords"
                        >
                            <!-- filter_list.svg -->
                            <img :src="require('@/assets/images/Reload.svg')" alt="filter icon" />
                        </button>
                    </div>
                </div>
            </div>
            <!-- date, comparison & filter  -->

            <!-- report of balance sheet -->
            <div class="w-90 center mt4">
                <!-- Business Details -->
                <div class="pa3 box-border">
                    <div
                        style="position:sticky;top:0;z-index: 2;"
                        class="bgTop"
                        :class="$store.state.Alert?.showMobileMenu && 'unZIndex'"
                    >
                        <OrgData heading="Inventory Report" />

                        <!-- statement of profit/lose -->
                        <div class="tc pv4">
                            <div class="b" style="color: #132C8C;">
                                <!-- Daily Sales Report of Bob Adams for the period list Jan 2021-Dec 31st 2021 -->
                                <!-- {{ state.filter }} Inventory Report of {{ orgData.org }} as at {{ formatDateInLocal(state.endDate) }} -->
                                Inventory Report of {{ orgData.org }} from {{ formatDateInLocal(state.startDate) }} to
                                {{ formatDateInLocal(state.endDate) }}
                            </div>
                        </div>
                    </div>

                    <!-- accounts & date assets, liabilities and co -->
                    <div class="ph2 pt4">
                        <!-- All, Sales, Expenses, Purchase & Other -->
                        <div class="scrollmenu w-100 center table-overflow">
                            <table class="w-100 tl mt3" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th class="pb3 pr3 bb b--black-20 tl">Date</th>
                                        <th class="pb3 pr3 bb b--black-20 tl">Opening Stock Count</th>
                                        <th class="pb3 pr3 bb b--black-20 tl">Value of Opening Stock</th>
                                        <th class="pb3 bb b--black-20 tl pr2">Quantity Purchases</th>
                                        <th class="pb3 bb b--black-20 tl pr2">Quantity Sold</th>
                                        <!-- <th class="pb3 bb b--black-20 tl pr2">Damaged/stolen/missing/purchase return qty</th> -->
                                        <!-- <th class="pb3 bb b--black-20 tl pr2">Sales return/stock introduced</th> -->
                                        <th class="pb3 bb b--black-20 pr2 tl">Closing Stock Qty</th>
                                        <th class="pb3 bb b--black-20 pr2 tl">Closing Stock Value</th>
                                    </tr>
                                </thead>
                                <tr v-for="result in state.result" :key="result._id" class="font-w1">
                                    <td class="pv3 pr3 bb b--black-20 tl">{{ result.date }}</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">{{ result.opening_stock_count }}</td>
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img :src="require('@/assets/images/file.svg')" class="pr1" alt="" />
                                        {{ formatAmount(result.opening_stock_value, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">{{ result.qty_purchased }}</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">{{ result.qty_sold }}</td>
                                    <!-- <td class="pv3 pr3 bb b--black-20 tl">{{ result.inventory_loss }}</td> -->
                                    <!-- <td class="pv3 pr3 bb b--black-20 tl">{{ result.sales_return }}</td> -->
                                    <td class="pv3 pr3 bb b--black-20 tl">{{ result.closing_stock_count }}</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">
                                        {{ formatAmount(result.closing_stock_value, $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- pagination -->
                        <div class="w-90 center pb5">
                            <div class="flex justify-between items-center pa3 font-w1" style="background: #F6F8FE;"></div>
                        </div>
                        <!-- pagination -->
                        <!-- save as & export button -->
                        <div class="flex justify-between pv4">
                            <div></div>
                            <div class="w-auto-l w-100 flex">
                                <button @click="downloadFile" class="flex items-center ml2">
                                    Export &nbsp;
                                    <!-- <img src="../../assets/images/down.svg" alt="" /> -->
                                    <img :src="require('@/assets/images/down.svg')" style="width: 10px" alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import AppWrapper from '@/layout/AppWrapper'
import OrgData from '@/components/OrgData'
import { computed, onMounted, reactive, watch } from 'vue'
import { formatDateInLocal, formatDateFilter, downloadCSV, formatAmount, formatDate } from '@/utils/lib'
import { useStore } from 'vuex'

export default {
    name: 'ExpenseReport ',
    components: { AppWrapper, OrgData },

    setup() {
        const store = useStore()
        const orgData = computed(() => store.state.Auth.userData)
        const report = computed(() => store.state.Reporting.inventoryReportsList)
        const state = reactive({
            currentDate: '',
            startDate: '',
            endDate: '',
            filter: 'monthly',
            result: []
        })

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()

        const tableHead = {
            monthly: 'Month',
            daily: 'Day',
            weekly: 'Week',
            annually: 'Year'
        }

        const handleInventoryRecords = () => {
            state.currentDate = new Date(year, month, day)
            if (state.filter.toLowerCase() === 'daily' && !state.startDate && !state.endDate) {
                state.startDate = new Date(year, month, 1)
                state.endDate = new Date(year, month, day)

                //Daily: 1st of current Month - Current date
                console.log('daily')
            } else if (state.filter.toLowerCase() === 'weekly' && !state.startDate && !state.endDate) {
                // 1st week of the Year to current week. Week starts from Monday//
                state.startDate = new Date(year, 0, 1)
                state.endDate = new Date(year, month, day)
                console.log('weekly')
            } else if (state.filter.toLowerCase() === 'monthly' && !state.startDate && !state.endDate) {
                // Monthly: Jan of the current year - Current month
                state.startDate = new Date(year, 0, 1)
                state.endDate = new Date(year, month, day)
                console.log('monthly')
            } else if (state.filter.toLowerCase() === 'annually' && !state.startDate && !state.endDate) {
                // 2021 to current Year
                state.startDate = new Date(2021, 0, 1)
                state.endDate = new Date(year, month, day)
                console.log('yearly')
            }
            if (state.startDate > state.endDate) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false
                })
            }

            if (!state.filter) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Filter is not selected',
                    status: false
                })
            }

            store.dispatch('Reporting/getInventoryReportList', {
                currentDate: state.currentDate,
                startDate: state.startDate,
                endDate: state.endDate,
                filter: state.filter
            })

            false && console.log(state.startDate, state.endDate, state.filter, 'state.startDate, state.endDate, state.filter')
        }

        onMounted(() => {
            const currentDate = new Date(year, month, day)
            const startDate = new Date(year, 0, 1)
            const endDate = new Date(year, month, day)
            const filter = 'monthly'

            console.log('currentDate: ', currentDate)
            console.log('startDate: ', startDate)
            console.log('endDate: ', endDate)

            const query = {
                currentDate: currentDate,
                startDate: startDate,
                endDate: endDate,
                filter: filter
            }

            state.startDate = formatDateFilter(startDate)
            state.endDate = formatDateFilter(endDate)

            store.dispatch('Reporting/getInventoryReportList', query)
        })

        const downloadFile = () => {
            downloadCSV(state.result)
        }

        watch(
            () => report.value,
            () => {
                if (report.value && report.value.result && report.value.result.length > 0) {
                    state.result = report.value.result
                }
            }
        )

        return {
            orgData,
            report,
            formatDateInLocal,
            tableHead,
            state,
            handleInventoryRecords,
            formatDateFilter,
            downloadFile,
            formatAmount,
            formatDate
        }
    }
}
</script>

<style scoped>
.table-overflow {
    overflow: auto;
}

.bgTop {
    background: white;
    width: 100%;
}

.unZIndex {
    z-index: -1 !important;
}
</style>
